$primaryColor: #e1700d ;// linear-gradient(to right, #47cbff 0%, #4dff6b 50%, #ff9f4d 100%);
$secondaryColor: #aaa;

#root {
    overflow: auto;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    background: linear-gradient(to right, #47cbff 0%, #4dff6b 50%, #ff9f4d 100%);

    .jumbotron {
        background-color: #FFF;
        box-shadow: 0px 0px 10px 1px seagreen;
    }
}

.flex-column {
    height: 100%;
}

.controls-container  {
    z-index: 2;
    position: relative;
    overflow: hidden;
    border-radius: 30px;
    background: rgba(255,255, 255, .3);
    max-width: 450px;
}

#screenMain {
    // height: 80%;
    position: relative;
    .video-item {
        width: 100%;
        height: 100%;
    }

}

.streamWrapper {
    position: relative;
    
    max-height: 100%;
    &.local {
        
        height: 100%;
    }

    .remoteInfo {
        position: absolute;
        top: 0;
        left: 45%;
        // left: 0;
        margin: 3px;
        font-weight: bold;
        text-shadow: 1px 1px $secondaryColor;
        z-index: 1;
    }
}

.videoItem {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;

    position: absolute;
    z-index: 0;
    // background: url(mel.jpg) no-repeat;
    background-size: 100% 100%;
    top: 0px;
    left: 0px; /* fixed to left. Replace it by right if you want.*/
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    
}


.remoteStreamsContainer {
    height: 100%;
    .streamWrapper {
        flex-basis: 500px!important;
    }
}

.bottomPanel {
    // min-height: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .lsWrapper {
        min-width: 150px;
        min-height: 150px;

        .settingsWrapper {
            position: absolute;
            height: 100%;
            right: 100%;
            top: 0;
        }
        .settingsButtonWrapper {
            position: relative;
            z-index: 1;

            display: flex;

            &:hover {
                opacity: 1;
                .settingsButton {
                    opacity: 1!important;
                }
            }

            flex:auto;
            justify-content: space-around;
            width: 100%;
            height: 100%;

            .settingsButton {

                opacity: 0;
                transition: .5 all .5;
                 &.active {
                    opacity: .5;
                }
                svg {
                    width: 60px;
                    height: 60px;
                    padding: 10px;
                    fill: $primaryColor;
                    filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 0.3));
                }
            }
        }
    }
    & > .row {
        height: 100%;
        position: relative;
    }
}

.sendFormWrapper {
    position: relative;
    .messagesWrapper {
        z-index: 2;
        min-width: 250px;
        position: absolute;
        bottom: 100%;
        padding-bottom: 5px;
        left: 0;
    }
}

  
  #sky {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  #shootingstars {
    margin: 0;
    padding: 0;
    width: 150vh;
    height: 100vw;
    position: fixed;
    overflow: hidden;
    transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%))
      rotate(120deg);
  }
  
  .wish {
    height: 2px;
    top: 300px;
    width: 100px;
    margin: 0;
    opacity: 0;
    padding: 0;
    background-color: white;
    position: absolute;
    background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px white);
    overflow: hidden;
  }
  


//   body { font-family: Georgia; text-align: center;}

	// .banner {width: 1200px; height: 340px; object-fit: cover; object-position: 0px -90px;}	

  	// .title { color: #F50890; font-size: 40px; text-shadow: 0px -1px 5px #F5F408;}

  	// .content {background-color: #FAD7A0; margin: 20px; padding: 30px; border-style: double; border-width: 5px; font-size: 18px; text-align: left; width: 1200px; display: inline-block; }

	.key { position: absolute; font-family: Helvetica; font-weight: 100; font-size: 12px;
		border: 1px solid rgba(32,32,32,0.2);
		border-radius: 0px 0px 5px 5px;
		cursor:pointer;
		box-shadow: 0px 5px 1px rgba(32,32,32,0.2);
		-webkit-transition: margin 0.05s ease, background-color 0.05s ease, box-shadow 0.05s ease; }
	.key:hover { background-color: #71DB90; }
		
	.key .label { position: absolute; bottom: 0px; text-align: center; left: 0px; right: 0px; }

	.keyLabel {font-weight: 900; text-shadow: 0px -1px 5px #EBF508; font-size: 12px;}

	.black { background-color: rgb(32,32,32); color: #ffffff; z-index: 1; text-shadow: 0px -1px 1px #FF0000; }
	
	.white { background-color: #F2F2DE; color: #3008F5; z-index: 0; text-shadow: 0px 1px 1px #08E8F5; }
	
	.keyboard-holder { margin: 0 auto; height: 100%; position:relative; user-select:none; -webkit-user-select:none;-moz-user-select:none;-o-user-select:none; }

    .keyboard-wrap {z-index: 10; width: 100%; height: 200px; position: fixed; top: 0;}
	/*.playing {background-color: #1BC0EA;}*/

